import { createAction } from 'redux-actions'

import { createApiActions } from '@tabeeb/modules/shared/utils/actions'

import { TenantConfig } from '../types'

export const getConfigForUpdateRequest = createAction<TenantConfig>('GET_CONFIG_FOR_UPDATE_REQUEST')
export const getConfigForUpdateSuccess = createAction<TenantConfig>('GET_CONFIG_FOR_UPDATE_SUCCESS')
export const getConfigForUpdateFailed = createAction<TenantConfig>('GET_CONFIG_FOR_UPDATE_FAILED')

export const handleUpdateConfig = createAction('HANDLE_UPDATE_CONFIG')

export const updateConfigRequest = createAction('UPDATE_CONFIG_REQUEST')
export const updateConfigSuccess = createAction('UPDATE_CONFIG_SUCCESS')
export const updateConfigFailed = createAction('UPDATE_CONFIG_FAILED')

export const setConfigForUpdate = createAction('SET_CONFIG_FOR_UPDATE')

export const getTenantsListRequest = createAction('GET_TENANTS_LIST_REQUEST')
export const getTenantsListSuccess = createAction('GET_TENANTS_LIST_SUCCESS')

export const getFtpServerSettingsRequest = createAction('GET_FTP_SERVER_SETTINGS_REQUEST')
export const getFtpServerSettingsSuccess = createAction('GET_FTP_SERVER_SETTINGS_SUCCESS')
export const getFtpServerSettingsFailed = createAction('GET_FTP_SERVER_SETTINGS_FAILED')

export const updateFtpServerSettingsRequest = createAction('UPDATE_FTP_SERVER_SETTINGS_REQUEST')
export const updateFtpServerSettingsSuccess = createAction('UPDATE_FTP_SERVER_SETTINGS_SUCCESS')
export const updateFtpServerSettingsFailed = createAction('UPDATE_FTP_SERVER_SETTINGS_FAILED')

export const saveFormSettings = createAction('TENANT_CONFIG_PAGE_SAVE_FORM_SETTINGS')

export const regenerateFtpPassword = createApiActions('REGENERATE_FTP_PASSWORD')
