import PropTypes from 'prop-types'

import ConfigTextField from '../../../tenantConfigPage/components/Fields/ConfigTextField'
import EndAdornmentRemove from '../../../tenantConfigPage/components/EndAdornmentRemove'

import './styles.less'

const TenantFormItem = ({ detachFormFromTenant, form }) => {
  const _detachFormFromTenant = (event) => {
    detachFormFromTenant(form.Id)
  }

  return (
    <ConfigTextField
      readOnly
      value={form.Name.length > 18 ? `${form.Name.slice(0, 18)}...` : form.Name}
      title={form.Name}
      InputProps={{
        endAdornment: <EndAdornmentRemove onClick={_detachFormFromTenant} />,
      }}
    />
  )
}

TenantFormItem.propTypes = {
  form: PropTypes.object.isRequired,
  detachFormFromTenant: PropTypes.func.isRequired,
}

export default TenantFormItem
