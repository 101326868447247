import PropTypes from 'prop-types'

import { Box } from '@mui/material'
import { Body2, Help } from '@tabeeb/uikit'

import { configFieldHelpText } from '../../services/enums'

const ConfigSetting = ({ name, label, children }) => {
  const helpText = configFieldHelpText[name]

  return (
    <Box display='flex' justifyContent='space-between' mt={1.5} minHeight={32}>
      <Box display='flex'>
        <Body2 mr={1}>{label}</Body2>
        {helpText && <Help text={helpText} />}
      </Box>
      <Box display='flex' flexDirection='column' alignItems='end' maxWidth='50%'>
        {children}
      </Box>
    </Box>
  )
}

ConfigSetting.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
}

export default ConfigSetting
