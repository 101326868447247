import { Action, handleActions } from 'redux-actions'

import * as tenantConfigActions from '../actions'

interface TenantConfig {
  bannerUrl: string
  logoUrl: string
  introImageUrl: string
  introImageTextColor: string
  name: string
  subdomain: string
  supportsPrograms: boolean
  apiUrl: string
}

interface ApiResponseAction<TRequest, TResponse> extends Action<TRequest> {
  response: {
    data: TResponse
  }
}

interface TenantConfigState extends TenantConfig {}

const defaultState: TenantConfigState = {
  bannerUrl: '',
  logoUrl: '',
  introImageUrl: '',
  introImageTextColor: '',
  name: '',
  subdomain: '',
  supportsPrograms: false,
  apiUrl: '',
}

export default handleActions<TenantConfigState>(
  {
    [tenantConfigActions.setConfigForUpdate.toString()](state, action: Action<TenantConfig>) {
      return { ...action.payload }
    },
    [tenantConfigActions.getConfigForUpdateSuccess.toString()](
      state,
      action: ApiResponseAction<TenantConfig, TenantConfig>
    ) {
      const config = action.response.data
      return { ...state, ...config }
    },
  },
  defaultState
)
